<template>
  <div class="icon-picker">
    <p class="icon-picker__label">
      {{ label }}
    </p>
    <Popper
      ref="popper"
      trigger="clickToOpen"
      :options="{
        placement: 'bottom-start',
        modifiers: { offset: { offset: '0,6' } },
      }"
      :disabled="isDisabled"
    >
      <div class="icon-picker__picker">
        <div
          v-for="icon in options"
          :key="icon"
          class="icon-picker__picker__option"
          @click="handleSelect(icon)"
        >
          <Icon :name="icon" />
        </div>
      </div>
      <div slot="reference" class="icon-picker__icon">
        <Icon v-if="value" :name="value" :size="36" />
      </div>
    </Popper>
  </div>
</template>

<script>
import Popper from "vue-popperjs";

export default {
  name: "IconPicker",
  components: { Popper },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSelect(value) {
      this.$emit("input", value);
      this.$refs.popper?.doClose();
    },
  },
};
</script>

<style scoped lang="scss">
.icon-picker {
  &__label {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
  }

  &__icon {
    width: 64px;
    height: 64px;
    padding: 12px;
    background: $white;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    cursor: pointer;
  }

  &__picker {
    background: $white;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    z-index: 1000;

    &__option {
      width: 42px;
      height: 42px;
      padding: 8px;
      background: $white;
      border: 1px solid $secondary-400;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
</style>
