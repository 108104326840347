var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{staticClass:"groupRow"},[_c('FormItem',{staticClass:"groupRow__field",attrs:{"rules":{
      required: !_vm.minimum,
      numeric: !Number.isNaN(_vm.minimum),
      minValue: 1,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"label":_vm.hasLabels ? 'Minimum' : '',"is-floating":false,"placeholder":"Minimum","is-small":"","is-error":isError},model:{value:(_vm.minimum),callback:function ($$v) {_vm.minimum=$$v},expression:"minimum"}})]}}])}),_c('FormItem',{staticClass:"groupRow__field",attrs:{"rules":{
      required: !_vm.maximum,
      numeric: !Number.isNaN(_vm.minimum),
      minValue: Number(_vm.minimum) + 1,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"label":_vm.hasLabels ? 'Maximum' : '',"is-floating":false,"placeholder":"Maximum","is-small":"","is-error":isError},model:{value:(_vm.maximum),callback:function ($$v) {_vm.maximum=$$v},expression:"maximum"}})]}}])}),_c('FormItem',{staticClass:"groupRow__field",attrs:{"rules":{ required: true, numeric: true, minValue: 1, maxValue: _vm.maximum }},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"label":_vm.hasLabels ? 'Number of People For Deposit' : '',"is-floating":false,"placeholder":"Persons to deposit","is-small":"","is-error":isError},model:{value:(_vm.personsToDeposit),callback:function ($$v) {_vm.personsToDeposit=$$v},expression:"personsToDeposit"}})]}}])}),_c('FormItem',[_c('Checkbox',{staticClass:"mb-12",model:{value:(_vm.isWalkInOnly),callback:function ($$v) {_vm.isWalkInOnly=$$v},expression:"isWalkInOnly"}},[_vm._v(" Walk-in only ")])],1),_c('FormItem',[_c('Checkbox',{staticClass:"mb-12",model:{value:(_vm.isLargeGroup),callback:function ($$v) {_vm.isLargeGroup=$$v},expression:"isLargeGroup"}},[_vm._v(" Large Group ")])],1),(_vm.isDeletable)?[(!['xs', 'sm'].includes(_vm.$mq))?_c('IconButton',{staticClass:"availabilitiesSlotRow__button",attrs:{"icon":"trash","variant":"danger"},on:{"click":function($event){return _vm.$emit('remove')}}}):_c('IconButton',{staticClass:"availabilitiesSlotRow__button",attrs:{"icon":"trash","variant":"danger"},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v(" "+_vm._s(_vm.$t("Delete slot"))+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }