<template>
  <Form class="groupRow">
    <FormItem
      class="groupRow__field"
      v-slot="{ isError }"
      :rules="{
        required: !minimum,
        numeric: !Number.isNaN(minimum),
        minValue: 1,
      }"
    >
      <Input
        v-model="minimum"
        :label="hasLabels ? 'Minimum' : ''"
        :is-floating="false"
        placeholder="Minimum"
        is-small
        :is-error="isError"
      />
    </FormItem>
    <FormItem
      class="groupRow__field"
      v-slot="{ isError }"
      :rules="{
        required: !maximum,
        numeric: !Number.isNaN(minimum),
        minValue: Number(minimum) + 1,
      }"
    >
      <Input
        v-model="maximum"
        :label="hasLabels ? 'Maximum' : ''"
        :is-floating="false"
        placeholder="Maximum"
        is-small
        :is-error="isError"
      />
    </FormItem>
    <FormItem
      class="groupRow__field"
      v-slot="{ isError }"
      :rules="{ required: true, numeric: true, minValue: 1, maxValue: maximum }"
    >
      <Input
        v-model="personsToDeposit"
        :label="hasLabels ? 'Number of People For Deposit' : ''"
        :is-floating="false"
        placeholder="Persons to deposit"
        is-small
        :is-error="isError"
      />
    </FormItem>
    <FormItem>
      <Checkbox v-model="isWalkInOnly" class="mb-12">
        Walk-in only
      </Checkbox>
    </FormItem>
    <FormItem>
      <Checkbox v-model="isLargeGroup" class="mb-12"> Large Group </Checkbox>
    </FormItem>
    <template v-if="isDeletable">
      <IconButton
        v-if="!['xs', 'sm'].includes($mq)"
        class="availabilitiesSlotRow__button"
        icon="trash"
        variant="danger"
        @click="$emit('remove')"
      />
      <IconButton
        v-else
        class="availabilitiesSlotRow__button"
        icon="trash"
        variant="danger"
        @click="$emit('remove')"
      >
        {{ $t("Delete slot") }}
      </IconButton>
    </template>
  </Form>
</template>
<script>
import { mapState } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getCurrencyByCountryCode } from "@/helpers/utils";

export default {
  name: "GroupRow",
  props: {
    value: {
      type: Object,
      default: null,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    hasLabels: {
      type: Boolean,
      default: false,
    },
    isTimeDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    productId: null,
    minimum: "",
    maximum: "",
    personsToDeposit: "",
    isWalkInOnly: false,
    isLargeGroup: false,
  }),
  created() {
    if (this.value.minimum) {
      this.minimum = +this.value.minimum;
    }
    if (this.value.maximum) {
      this.maximum = +this.value.maximum;
    }
    if (this.value.personsToDeposit) {
      this.personsToDeposit = +this.value.personsToDeposit;
    }
    if (this.value.isWalkInOnly) {
      this.isWalkInOnly = this.value.isWalkInOnly;
    }
    if (this.value.isLargeGroup) {
      this.isLargeGroup = this.value.isLargeGroup;
    }
  },
  watch: {
    minimum() {
      this.$emit("change", this.handleChange());
    },
    maximum() {
      this.$emit("change", this.handleChange());
    },
    personsToDeposit() {
      this.$emit("change", this.handleChange());
    },
    isWalkInOnly() {
      this.$emit("change", this.handleChange());
    },
    isLargeGroup() {
      this.$emit("change", this.handleChange());
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.products.list,
      currencySymbol: (state) =>
        getCurrencyByCountryCode(state.venues.selectedVenue?.country).symbol,
    }),
    dollarsMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: this.currencySymbol,
      });
    },
  },
  methods: {
    handleChange() {
      return {
        minimum: +this.minimum,
        maximum: +this.maximum,
        personsToDeposit: +this.personsToDeposit,
        isWalkInOnly: this.isWalkInOnly,
        isLargeGroup: this.isLargeGroup,
      };
    },
  },
};
</script>
<style lang="scss">
.groupRow {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__section {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  &__timeWrapper {
    flex: 1 0 210px;

    @media (max-width: $media-laptop - 1) {
      flex-basis: 100%;
    }
  }

  &__time {
    display: flex;
    gap: 3px;
    border-radius: 8px;
    border: 1px solid $secondary-300;
    padding: 5px 9px;
    height: 54px;
  }

  &__timeButton {
    border-radius: 6px;
    border: 1px solid $secondary-300;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $secondary-400;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    cursor: pointer;

    &.m-active {
      background: $secondary-300;
      color: $secondary-500;
    }
  }

  &__field {
    flex: 1 0 85px;

    @media (max-width: $media-laptop - 1) {
      flex-basis: 45%;
    }
  }

  &__button {
    margin-left: auto;

    @media (max-width: $media-laptop - 1) {
      margin-right: auto;
      margin-left: 0;
      margin-top: 0;
    }

    &.m-labeled {
      margin-top: 20px;
    }
  }
}
</style>
