<template>
  <Form v-slot="{ invalid, changed }" class="product-form">
    <div>
      <FormItem
        v-if="!isDisabled"
        class="product-form__is-active"
        :class="{ active: isProductActive }"
      >
        Product is active
        <Switcher v-model="isProductActive" />
      </FormItem>
      <div class="grid-2 mb-40">
        <FormItem v-slot="{ isError }" rules="required">
          <Input
            v-model="form.name"
            label="Product name"
            placeholder="Product name"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem v-slot="{ isError }" rules="required">
          <Input
            v-model="form.title"
            label="Widget title"
            placeholder="Widget title"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem v-slot="{ isError }" rules="required">
          <Input
            v-model="form.description"
            label="Description"
            placeholder="Description"
            is-textarea
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <div style="display: flex; gap: 12px">
          <FormItem>
            <ImageUploader
              v-model="form.image"
              label="Image"
              scope="products"
              :max-size="200"
              :is-disabled="isDisabled"
            />
          </FormItem>
          <FormItem>
            <IconPicker
              v-model="form.icon"
              :options="iconOptions"
              label="Icon"
              :is-disabled="isDisabled"
            />
          </FormItem>
        </div>
      </div>
      <div class="my-40">
        <FormItem class="grid-2__full-item my-40">
          <RadioGroup
            v-model="typeOfPricing"
            label="Type of pricing"
            :options="typeOfPricingOptions"
            :isRow="true"
            variant="black"
          />
        </FormItem>
        <div
          v-if="
            typeOfPricing &&
            typeOfPricing.value === BookingPricingTypeEnum.GROUP_RANGE
          "
        >
          <IconButton class="my-40" icon="plus" @click="handleGroupAdd">
            Add group
          </IconButton>
          <GroupRow
            v-for="(group, index) in groups"
            :key="group.uuid"
            :value="group"
            :is-deletable="groups.length > 1"
            :has-labels="index === 0"
            @change="groups[index] = { ...$event, uuid: group.uuid }"
            @remove="groups.splice(index, 1)"
          />
        </div>
      </div>
      <div class="grid-2">
        <FormItem v-slot="{ isError }" rules="required|numeric">
          <Input
            v-model="form.duration"
            label="Base duration (minutes)"
            placeholder="Duration"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem v-slot="{ isError }" rules="required|numeric">
          <Input
            v-model="form.breakTime"
            label="Base break time (minutes)"
            placeholder="Break time"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem
          v-slot="{ isError }"
          :rules="{ decimal: 2, required: !form.fixedPrice }"
        >
          <FormSuffix :suffix="currencySymbol">
            <Input
              v-model="form.price"
              label="Base slot price per person"
              placeholder="Price"
              :is-error="isError"
              :is-disabled="isDisabled"
            />
          </FormSuffix>
        </FormItem>
        <template
          v-if="
            typeOfPricing &&
            typeOfPricing.value === BookingPricingTypeEnum.PERSON
          "
        >
          <FormItem
            :rules="{ decimal: 2, required: !form.price }"
            v-slot="{ isError }"
          >
            <FormSuffix :suffix="currencySymbol">
              <Input
                v-model="form.fixedPrice"
                label="Base fixed price"
                placeholder="Fixed price"
                :is-error="isError"
                :is-disabled="isDisabled"
                tooltip="Fixed price has a priority over price per person"
              />
            </FormSuffix>
          </FormItem>
          <FormItem rules="numeric" v-slot="{ isError }">
            <Input
              v-model="form.minPlayers"
              label="Minimum participants"
              placeholder="Minimum participants"
              :is-error="isError"
              :is-disabled="isDisabled"
            />
          </FormItem>
          <FormItem rules="numeric" v-slot="{ isError }">
            <Input
              v-model="form.maxPlayers"
              label="Maximum participants"
              placeholder="Maximum participants"
              :is-error="isError"
              :is-disabled="isDisabled"
            />
          </FormItem>
        </template>
        <FormItem v-slot="{ isError }" rules="required">
          <Select
            v-model="form.cutoffTime"
            :options="cutoffTimeOptions"
            label="Cutoff time"
            placeholder="Cutoff time"
            :is-error="isError"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem>
          <Select
            v-model="form.termsId"
            :options="termsOptions"
            label="Terms & conditions"
            placeholder="Terms & conditions"
            :is-disabled="isDisabled"
            is-clearable
          />
        </FormItem>
        <div class="grid-2">
          <FormItem rules="numeric" v-slot="{ isError }">
            <Input
              v-model="form.cancellationPeriodAmount"
              label="Cancellation period amount"
              placeholder="Cancellation period amount"
              :is-error="isError"
              :is-disabled="isDisabled"
            />
          </FormItem>
          <FormItem>
            <Select
              v-model="form.cancellationPeriodUnit"
              :options="cancellationOptions"
              label="Cancellation period unit"
              placeholder="Cancellation period unit"
              :is-disabled="isDisabled"
            />
          </FormItem>
        </div>
        <div v-if="!isGroupRange" class="grid-2">
          <FormItem :class="{ 'grid-2__full-item': !form.depositType }">
            <Select
              v-model="form.depositType"
              :options="depositTypeOptions"
              :label="$t('Deposit type')"
              :placeholder="$t('Deposit type')"
              :is-disabled="isDisabled"
              is-clearable
            />
          </FormItem>
          <FormItem
            v-if="form.depositType"
            :rules="{
              decimal: 2,
              required: true,
              ...(form.depositType === PromoRateTypeEnum.PERCENT
                ? {
                    maxValue: 100,
                  }
                : {}),
            }"
            v-slot="{ isError }"
          >
            <FormSuffix
              :suffix="
                form.depositType === PromoRateTypeEnum.PERCENT ? '%' : '$'
              "
            >
              <Input
                v-model="form.deposit"
                label="Deposit rate"
                placeholder="Deposit rate"
                :is-error="isError"
                :max="3"
              />
            </FormSuffix>
          </FormItem>
        </div>
        <FormItem
          v-if="
            typeOfPricing &&
            typeOfPricing.value === BookingPricingTypeEnum.GROUP_RANGE
          "
        >
          <Select
            v-model="form.largeGroupDisclaimerId"
            :options="termsOptions"
            label="Large group disclaimer"
            placeholder="Large group disclaimer"
            :is-disabled="isDisabled"
            is-clearable
          />
        </FormItem>
        <div class="grid-2 product-form__holidays">
          <FormItem>
            <Select
              v-model="form.holidayDisclaimerId"
              :options="termsOptions"
              label="Holiday disclaimer"
              placeholder="Holiday disclaimer"
              :is-disabled="isDisabled"
              is-clearable
            />
          </FormItem>
          <Guard
            permission="calendar.setNonWorkingDays"
            v-slot="{ isAvailable }"
          >
            <div v-if="isAvailable">
              <Button
                variant="secondary"
                is-outlined
                :is-disabled="!form.holidayDisclaimerId"
                @click="$modal.show('holidaysModal')"
              >
                {{ $t("Manage holidays") }}
              </Button>
              <FormItem>
                <CalendarSelectDaysModal
                  modal-name="holidaysModal"
                  title="Select holidays"
                  :value="form.holidays"
                  @input="handleSaveHolidays"
                />
              </FormItem>
            </div>
          </Guard>
        </div>
        <div
          class="grid-2__full-item"
          v-if="venue.widget === WidgetThemeEnum.BADAXE"
        >
          <FormItem>
            <Input
              v-model="form.widgetNote"
              label="Widget note"
              placeholder="Widget note"
              :is-textarea="true"
            />
          </FormItem>
        </div>
        <FormItem class="grid-2__full-item">
          <Select
            v-model="form.resources"
            :options="resourcesOptions"
            is-multi
            :tags-limit="['xs'].includes($mq) ? 2 : 5"
            :label="$t('Lanes')"
            :placeholder="$t('Lanes')"
            :is-disabled="isDisabled"
          />
        </FormItem>
      </div>
      <template v-if="!isDisabled || Object.keys(form.customFields).length">
        <div class="divider my-40" />
        <FormItem>
          <CustomFieldsController
            v-model="form.customFields"
            label="Custom fields"
            :is-disabled="isDisabled"
          />
        </FormItem>
      </template>
      <div class="divider my-40" />
      <div class="grid-2">
        <FormItem v-slot="{ isError }">
          <MultipleSelect
            v-model="form.upsellItemIds"
            :options="upsellItemsOptions"
            label="Upsell merchandise"
            placeholder="Select upsell merchandise"
            :is-error="isError"
            :is-disabled="isDisabled"
            add-button-text="Add upsell merchandise"
          />
        </FormItem>
      </div>
      <div class="divider my-40" />
      <div class="grid-2">
        <FormItem v-slot="{ isError }">
          <MultipleSelect
            v-model="form.taxes"
            :options="taxesOptions"
            label="Taxes"
            placeholder="Tax"
            :is-error="isError"
            :is-disabled="isDisabled"
            add-button-text="Add tax"
          />
        </FormItem>
      </div>
    </div>
    <div class="product-form__actions">
      <Button
        v-if="!isDisabled"
        :is-disabled="invalid || !changed"
        :is-block="['xs', 'sm'].includes($mq)"
        :is-loading="isSubmitting"
        @click="handleSubmit"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="products.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isEditing && isAvailable"
          is-outlined
          variant="danger"
          :is-block="['xs', 'sm'].includes($mq)"
          :is-disabled="isSubmitting"
          :is-loading="isDeleting"
          @click="handleDelete"
        >
          Delete product
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import FormSuffix from "@/components/common/FormSuffix";
import { mapActions, mapState } from "vuex";
import MultipleSelect from "@/components/common/MultipleSelect";
import { getCurrencyByCountryCode } from "@/helpers/utils";
import dialog from "@/plugins/dialog";
import CustomFieldsController from "@/components/common/customFields/CustomFieldsController";
import ImageUploader from "@/components/common/ImageUploader";
import Guard from "@/components/common/Guard";
import GroupRow from "@/components/products/GroupRow";
import { v4 as uuid } from "uuid";
import {
  BookingPricingTypeEnum,
  PromoRateTypeEnum,
  WidgetThemeEnum,
} from "@/helpers/enums";
import CalendarSelectDaysModal from "@/components/calendar/modals/CalendarSelectDaysModal.vue";
import IconPicker from "@/components/common/IconPicker.vue";

export default {
  name: "ProductForm",
  components: {
    IconPicker,
    CalendarSelectDaysModal,
    Guard,
    ImageUploader,
    CustomFieldsController,
    MultipleSelect,
    FormSuffix,
    GroupRow,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      BookingPricingTypeEnum,
      WidgetThemeEnum,
      cutoffTimeOptions: [
        {
          name: "1 hour before",
          value: 1,
        },
        {
          name: "2 hours before",
          value: 2,
        },
        {
          name: "3 hours before",
          value: 3,
        },
      ],
      iconOptions: [
        "product-archery-1",
        "product-gun-1",
        "product-gun-2",
        "product-bowling-1",
        "product-archery-2",
        "product-archery-3",
        "product-archery-4",
        "product-rifle",
        "product-archery-5",
        "product-axe-1",
        "product-archery-6",
        "product-archery-7",
        "product-wheel",
        "product-axe-swing",
        "product-axe-firefighter",
        "product-bowling-2",
        "product-bowling-3",
        "product-axe-tool",
        "product-axe-hatchet",
        "product-axe-2",
        "product-bowling-alley",
        "product-archery-8",
        "product-billiards",
        "product-axe-3",
        "product-archery-9",
        "product-axe-4",
      ],
      cancellationOptions: [
        {
          value: "hour",
          name: "Hours",
        },
        {
          value: "day",
          name: "Days",
        },
        {
          value: "week",
          name: "Weeks",
        },
      ],
      PromoRateTypeEnum,
      depositTypeOptions: [
        {
          name: "Percentage",
          value: PromoRateTypeEnum.PERCENT,
        },
        {
          name: "Fixed",
          value: PromoRateTypeEnum.FIXED,
        },
      ],
      typeOfPricingOptions: [
        {
          id: 1,
          name: "Per person",
          value: BookingPricingTypeEnum.PERSON,
        },
        {
          id: 2,
          name: `By group range`,
          value: BookingPricingTypeEnum.GROUP_RANGE,
        },
      ],
      typeOfPricing: null,
      groups: [
        {
          uuid: uuid(),
        },
      ],
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      taxes: (state) => state.taxes.list,
      resources: (state) => state.resources.list,
      venue: (state) => state.venues.selectedVenue,
      product: (state) => state.selectedProduct,
      terms: (state) => state.terms.list,
      upsellItems: (state) => state.upsellItems.list,
    }),
    currencySymbol() {
      return getCurrencyByCountryCode(this.venue.country).symbol;
    },
    isGroupRange() {
      return this.typeOfPricing.value === "group-range";
    },
    taxesOptions() {
      return this.taxes.map((tax) => ({
        value: tax.id,
        name: `${tax.name} (${tax.rate}%)`,
      }));
    },
    termsOptions() {
      return this.terms.map((item) => ({
        value: item.id,
        name: item.name,
      }));
    },
    upsellItemsOptions() {
      return this.upsellItems.map((item) => ({
        value: item.id,
        name: `${item.name} ($${item.price})`,
      }));
    },
    resourcesOptions() {
      return this.resources.map((resource) => ({
        value: resource.id,
        name: resource.id,
      }));
    },
    isProductActive: {
      get() {
        return !this.form.isPaused;
      },
      set(newValue) {
        this.form.isPaused = !newValue;
      },
    },
    isEditing() {
      return this.$route.name === "EditProduct";
    },
  },
  async created() {
    this.typeOfPricing = this.form.typeOfPricing
      ? this.typeOfPricingOptions.find(
          (typeOfPricing) => typeOfPricing.value === this.form.typeOfPricing
        )
      : this.typeOfPricingOptions[0];

    this.groups = this.form.groups.length ? this.form.groups : this.groups;

    await Promise.allSettled([
      this.fetchTaxes(),
      this.fetchResources(),
      this.fetchTerms(),
      this.fetchUpsellItems(),
    ]);
  },
  methods: {
    ...mapActions({
      fetchTaxes: "taxes/fetchTaxes",
      fetchTerms: "terms/fetchTerms",
      fetchResources: "resources/fetchResources",
      fetchUpsellItems: "upsellItems/fetchUpsellItems",
      deleteProduct: "products/deleteProduct",
    }),
    createGroup(data) {
      let group = { uuid: uuid() };

      if (data) {
        group = {
          ...data,
          ...group,
          maximum: data.maximum + 2,
          minimum: data.maximum + 1,
        };
      } else {
        group.minumum = 1;
        group.maximum = "";
        group.personsToDeposit = "";
      }

      return group;
    },
    handleGroupAdd() {
      const lastGroup = this.groups[this.groups.length - 1];
      let data;
      if (lastGroup) {
        data = {
          ...lastGroup,
        };
      }

      const group = this.createGroup(data);
      this.groups.push(group);
    },
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "All product data will be lost",
        okText: "Delete product",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          const { id } = this.$route.params;
          await this.deleteProduct({ id });
          await this.$router.push({ name: "Products" });
        } finally {
          this.isDeleting = false;
        }
      }
    },
    handleSubmit() {
      if (this.typeOfPricing.value === BookingPricingTypeEnum.PERSON) {
        this.form.groups = [];
        this.groups = [];
      } else {
        this.form.minPlayers = this.form.groups?.length
          ? Math.min(...this.form.groups.map((group) => group.minimum))
          : undefined;
        this.form.maxPlayers = this.form.groups?.length
          ? Math.max(...this.form.groups.map((group) => group.maximum))
          : undefined;
        this.form.groups.length
          ? (this.groups = this.form.groups)
          : (this.groups[0] = this.createGroup());
      }

      this.$emit("submit", this.form);
    },
    handleSaveHolidays(days) {
      this.form.holidays = days;
      this.$modal.hide("holidaysModal");
    },
  },
  watch: {
    "form.depositType"(val) {
      if (val) {
        this.form.deposit = 0;
      } else {
        this.form.deposit = null;
      }
    },
    groups(value) {
      this.form.groups = value;
    },
    typeOfPricing(typeOfPrice) {
      this.form.typeOfPricing = typeOfPrice.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__is-active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background-color: $secondary-200;
    font-size: 14px;
    line-height: 23px;
    color: $secondary-500;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 40px;
    transition: 0.3s;

    &.active {
      background-color: #3fab7714;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &__holidays {
    align-items: flex-end;
  }
}
</style>
